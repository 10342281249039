import * as React from 'react'

export default function BlogPosts ({ docs }) {
  if (!docs) return null

  return (
    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      {docs.nodes.map(({ url, data: blogPostData }, index) => (
        <div key={index} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <a href={url} className="flex-shrink-0">
            <img className="h-48 w-full object-cover" src={blogPostData.cover_image.url} alt={blogPostData.cover_image.alt} />
          </a>
          <div className="flex-1 bg-white p-6 pt-4 flex flex-col justify-between">
            <div className="flex-1">
              <div>
                {blogPostData.categories.map(({ category }, index) => (
                  <a key={index} href={category?.document?.url} className="inline-block mr-2 mb-2">
                    <span
                      className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
                      style={{
                        backgroundColor: category?.document?.data?.bg_color,
                        color: category?.document?.data?.text_color
                      }}
                    >
                      {category?.document?.data?.name}
                    </span>
                  </a>
                ))}
              </div>

              <a href={url} className="block">
                <p className="text-xl font-semibold text-gray-900">{blogPostData.title}</p>
                <p className="mt-3 text-base text-gray-500">{blogPostData.description}</p>
              </a>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <a href={blogPostData.author_link.url}>
                  <span className="sr-only">{blogPostData.author_full_name}</span>
                  <img className="h-10 w-10 rounded-full" src={blogPostData.author_avatar.url} alt={blogPostData.author_avatar.alt} />
                </a>
              </div>
              <div className="ml-3">
                <span className="text-sm font-medium text-gray-900">
                  <a href={blogPostData.author_link.url} className="hover:underline">
                    {blogPostData.author_full_name}
                  </a>
                </span>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={blogPostData.datetime}>{blogPostData.date}</time>
                  {blogPostData.reading_time && (
                    <>
                      <span aria-hidden="true">&middot;</span>
                      <span>Lecture {blogPostData.reading_time} min.</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
