import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BlogPosts from '../components/blog-posts'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import Head from '../components/head'

export const query = graphql`
  query ($url: String!) {
    prismicBlogCategory(url: { eq: $url }) {
      uid
      data {
        bg_color
        name
        description
      }
    }
    allPrismicBlogPost(
      filter: { data: { categories: { elemMatch: { category: { url: { eq: $url } } } } } }
      sort: { fields: data___date, order: DESC }
      limit: 1000
      skip: 0
    ) {
      nodes {
        _previewable
        id
        uid
        url
        data {
          title
          description
          date(formatString: "LL", locale: "fr-FR")
          reading_time
          author_avatar {
            url
            alt
          }
          author_full_name
          author_link {
            url
          }
          cover_image {
            url
          }
          categories {
            category {
              document {
                ... on PrismicBlogCategory {
                  id
                  url
                  data {
                    bg_color
                    text_color
                    name
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

const Blog = ({ data }) => {
  if (!data) return null

  const category = data.prismicBlogCategory?.data
  const docs = data.allPrismicBlogPost

  return (
    <Layout>
      <Head
        title={category?.name}
        description={category?.description}
      />

      <div className="content-container space-around-row relative bg-gray-50 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>

        <div className="relative max-w-7xl mx-auto">
          <a href="/blog/" className='flex flex-row items-center -mt-4 mb-2'><ArrowLeftIcon className='w-4 h-4 mr-2' /> tous les articles</a>

          <div className="flex flex-col lg:flex-row items-center lg:items-start">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl rc-highlight-text" style={{ '--highlight-color': category?.bg_color }}>{category?.name}</h2>
            <p className="ml-0 lg:ml-12 text-justify mt-2.5 max-w-2xl text-xl text-gray-500 mb-0">{category?.description}</p>
          </div>

          <BlogPosts docs={docs} />
        </div>
      </div>
    </Layout>
  )
}

export default Blog
